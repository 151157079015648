import Vue from 'vue'
import Vuex from 'vuex'
import { setItem } from '../hooks/useLocalStorage'

import { auth } from './auth.module'
import {leads} from './modules/leads-modules/leads.module'






Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        empresaFiltros: {
            q: null,
            estados: [],
            municipios: [],
            bairro: null,
            logradouro: null,
            cep: null,
            cnaes: [],
            dataAberturaInicio: null,
            dataAberturaFim: null,
            capitalSocialMin: null,
            capitalSocialMax: null,
            matrizFilial: null,
            filiaisMin: null,
            filiaisMax: null,
            opcaoSimples: null,
            portes: [],
            naturezaJuridicas: [],
            departamentos: [],
            nivelHierarquicos: [],
            contatoComTelefone: null,
            faturamentoMin: null,
            faturamentoMax: null,
            funcionariosMin: null,
            funcionariosMax: null,
            cnpjs: null,
            empresaSituacaos: [{ text: "Ativa", value: 2 }], // Trazer ativas por padrão, inicialmente.
        },
        medicoFiltros: {
            areaAtuacaos: [],
            medicoSituacaos: [],
            sites: [],
            cbos: [],
            especialidades: [],
            estados: [],
            dataRegistro: null,
            estabAtividades: [],
            estabTipos: [],
            estabEstados: [],
            estabMunicipios: [],
            vinculos: [],
            formasContato: [],
            crms: null,
        },

        mostrarNovoProjeto: false,
        // mudarVisualizacao: false,
        mudarVisualizacao: true,
        valorModalEditarStatus: undefined,
        valorModalEditarRecusas: undefined,
        call: {
            showComponent: false,
        },
        phone: "",
        inCall: false,
        projetoSelecionado: null,
        showWhiteMode: true,
        listenerChangeProjectList: false,





    },

    mutations: {

        // Filtros Empresas
        atualizarPalavraChave(state, value) {
            // console.log("store => ", value);
            state.empresaFiltros.q = value
        },
        atualizarEstados(state, value) {
            state.empresaFiltros.estados = value
            state.medicoFiltros.estados = value
        },
        atualizarMunicipios(state, value) {
            state.empresaFiltros.municipios = value
        },
        atualizarBairro(state, value) {
            state.empresaFiltros.bairro = value
        },
        atualizarLogradouro(state, value) {
            state.empresaFiltros.logradouro = value
        },
        atualizarCep(state, value) {
            state.empresaFiltros.cep = value
        },
        atualizarCnaes(state, value) {
            state.empresaFiltros.cnaes = value
        },
        atualizarDataAberturaInicio(state, value) {
            state.empresaFiltros.dataAberturaInicio = value
        },
        atualizarDataAberturaFim(state, value) {
            state.empresaFiltros.dataAberturaFim = value
        },
        atualizarCapitalSocialMin(state, value) {
            state.empresaFiltros.capitalSocialMin = value
        },
        atualizarCapitalSocialMax(state, value) {
            state.empresaFiltros.capitalSocialMax = value
        },
        atualizarMatrizFilial(state, value) {
            state.empresaFiltros.matrizFilial = value
        },
        atualizarContatoComTelefone(state, value) {
            state.empresaFiltros.contatoComTelefone = value
        },
        atualizarFiliaisMin(state, value) {
            state.empresaFiltros.filiaisMin = value
        },
        atualizarFiliaisMax(state, value) {
            state.empresaFiltros.filiaisMax = value
        },
        atualizarOpcaoSimples(state, value) {
            state.empresaFiltros.opcaoSimples = value
        },
        atualizarPortes(state, value) {
            state.empresaFiltros.portes = value
        },
        atualizarNaturezaJuridicas(state, value) {
            state.empresaFiltros.naturezaJuridicas = value
        },
        atualizarDepartamentos(state, value) {
            state.empresaFiltros.departamentos = value
        },
        atualizarNivelHierarquicos(state, value) {
            state.empresaFiltros.nivelHierarquicos = value
        },
        atualizarFaturamentoMin(state, value) {
            state.empresaFiltros.faturamentoMin = value
        },
        atualizarFaturamentoMax(state, value) {
            state.empresaFiltros.faturamentoMax = value
        },
        atualizarFuncionariosMin(state, value) {
            state.empresaFiltros.funcionariosMin = value
        },
        atualizarFuncionariosMax(state, value) {
            state.empresaFiltros.funcionariosMax = value
        },
        atualizarCNPJs(state, value) {
            state.empresaFiltros.cnpjs = value
        },
        atualizarEmpresaSituacaos(state, value) {
            state.empresaFiltros.empresaSituacaos = value
        },

        // Filtros Doktor
        atualizarAreaAtuacaos(state, value) {
            state.medicoFiltros.areaAtuacaos = value
        },
        atualizarmedicoSituacaos(state, value) {
            state.medicoFiltros.medicoSituacaos = value
        },
        atualizarSites(state, value) {
            state.medicoFiltros.sites = value
        },
        atualizarEspecialidades(state, value) {
            state.medicoFiltros.especialidades = value
        },
        atualizarEstabEstados(state, value) {
            state.medicoFiltros.estabEstados = value
        },
        atualizarEstabMunicipios(state, value) {
            state.medicoFiltros.estabMunicipios = value
        },
        atualizarDataRegistro(state, value) {
            state.medicoFiltros.dataRegistro = value
        },
        atualizarEstabAtividades(state, value) {
            state.medicoFiltros.estabAtividades = value
        },
        atualizarEstabTipos(state, value) {
            state.medicoFiltros.estabTipos = value
        },
        // medicoFiltros.cbos 
        atualizarCbos(state, value) {
            state.medicoFiltros.cbos = value
        },
        atualizarFormasContato(state, value) {
            state.medicoFiltros.formasContato = value
        },
        atualizarVinculos(state, value) {
            state.medicoFiltros.vinculos = value
        },
        atualizarCRMs(state, value) {
            state.medicoFiltros.crms = value
        },
        limparRegistro(state) {
            state.medicoFiltros = {
                areaAtuacaos: [],
                medicoSituacaos: [],
                sites:[],
                cbos: [],
                especialidades: [],
                estados: [],
                dataRegistro: null,
                estabAtividades: [],
                estabTipos: [],
                estabEstados: [],
                estabMunicipios: [],
                vinculos: [],
                formasContato: [],
                crms: null
            }
        },

        mostrarNovoProjetoChangeState(state) {
            state.mostrarNovoProjeto = !state.mostrarNovoProjeto

        },
        // dashboard

        changeMudarVisualizacao(state) {
            state.mudarVisualizacao = !state.mudarVisualizacao
        },

        atualizarValorModalEditarStatus(state, value) {
            state.valorModalEditarStatus = value
        },
        atualizarValorModalEditarRecusas(state, value) {
            state.valorModalEditarRecusas = value

        },
        setCall(state, value) {


            state.call = {
                showComponent: value === undefined ? !state.call.showComponent : value,
            }


        },
        setPhone(state, value) {
            state.phone = value

        },
        setInCall(state, value) {
            state.inCall = value
        },
        atualizarProjetoSelecionado(state, value) {
            state.projetoSelecionado = value
            setItem("selected-project", value)
        },
        setWhiteAndDarkMode(state, value) {
            // value is boolean 

            state.showWhiteMode = value
            localStorage.setItem("WHITEMODE", JSON.stringify(state.showWhiteMode)); // BOOLEAN           
        },
        atualizarListenerChangeProjectList(state) {
            state.listenerChangeProjectList = !state.listenerChangeProjectList
        },






    },
    actions: {
        // Filtros Empresas
        atualizarPalavraChaveAction({ commit }, value) {
            commit('atualizarPalavraChave', value)
        },
        atualizarEstadosAction({ commit }, value) {
            commit('atualizarEstados', value)

        },
        atualizarMunicipiosAction({ commit }, value) {
            commit('atualizarMunicipios', value)
        },
        atualizarBairroAction({ commit }, value) {
            commit('atualizarBairro', value)
        },
        atualizarLogradouroAction({ commit }, value) {
            commit('atualizarLogradouro', value)
        },
        atualizarCepAction({ commit }, value) {
            commit('atualizarCep', value)
        },
        atualizarCnaesAction({ commit }, value) {
            commit('atualizarCnaes', value)
        },
        atualizarDataAberturaInicioAction({ commit }, value) {
            commit('atualizarDataAberturaInicio', value)
        },
        atualizarDataAberturaFimAction({ commit }, value) {
            commit('atualizarDataAberturaFim', value)
        },
        atualizarCapitalSocialMinAction({ commit }, value) {
            commit('atualizarCapitalSocialMin', value)
        },
        atualizarCapitalSocialMaxAction({ commit }, value) {
            commit('atualizarCapitalSocialMax', value)
        },
        atualizarMatrizFilialAction({ commit }, value) {
            commit('atualizarMatrizFilial', value)
        },
        atualizarContatoComTelefoneAction({ commit }, value) {
            commit('atualizarContatoComTelefone', value)
        },
        atualizarFiliaisMinAction({ commit }, value) {
            commit('atualizarFiliaisMin', value)
        },
        atualizarFiliaisMaxAction({ commit }, value) {
            commit('atualizarFiliaisMax', value)
        },
        atualizarOpcaoSimplesAction({ commit }, value) {
            commit('atualizarOpcaoSimples', value)
        },
        atualizarPortesAction({ commit }, value) {
            commit('atualizarPortes', value)
        },
        atualizarNaturezaJuridicasAction({ commit }, value) {
            commit('atualizarNaturezaJuridicas', value)
        },
        atualizarDepartamentosAction({ commit }, value) {
            commit('atualizarDepartamentos', value)
        },
        atualizarNivelHierarquicosAction({ commit }, value) {
            commit('atualizarNivelHierarquicos', value)
        },
        atualizarFaturamentoMinAction({ commit }, value) {
            commit('atualizarFaturamentoMin', value)
        },
        atualizarFaturamentoMaxAction({ commit }, value) {
            commit('atualizarFaturamentoMax', value)
        },
        atualizarFuncionariosMinAction({ commit }, value) {
            commit('atualizarFuncionariosMin', value)
        },
        atualizarFuncionariosMaxAction({ commit }, value) {
            commit('atualizarFuncionariosMax', value)
        },
        atualizarCNPJsAction({ commit }, value) {
            commit('atualizarCNPJs', value)
        },
        atualizarEmpresaSituacaosAction({ commit }, value) {
            commit('atualizarEmpresaSituacaos', value)
        },

        // Filtros Doktor
        atualizarAreaAtuacaosAction({ commit }, value) {
            commit('atualizarAreaAtuacaos', value)
        },
        atualizarSitesAction({ commit }, value) {
            commit('atualizarSites', value)
        },
        atualizarmedicoSituacaosAction({ commit }, value) {
            commit('atualizarmedicoSituacaos', value)
        },
        atualizarEspecialidadesAction({ commit }, value) {
            commit('atualizarEspecialidades', value)
        },
        atualizarEstabEstadosAction({ commit }, value) {
            commit('atualizarEstabEstados', value)
        },
        atualizarEstabMunicipiosAction({ commit }, value) {
            commit('atualizarEstabMunicipios', value)
        },
        atualizarDataRegistroAction({ commit }, value) {
            commit('atualizarDataRegistro', value)
        },
        atualizarEstabAtividadesAction({ commit }, value) {
            commit('atualizarEstabAtividades', value)
        },
        atualizarEstabTiposAction({ commit }, value) {
            commit('atualizarEstabTipos', value)
        },
        atualizarCbosAction({ commit }, value) {
            commit('atualizarCbos', value)
        },
        atualizarVinculosAction({ commit }, value) {
            commit('atualizarVinculos', value)
        },
        atualizarCRMsAction({ commit }, value) {
            commit('atualizarCRMs', value)
        },
        //Geral
        atualizarProjetoSelecionadoAction({ commit }, value) {
            commit('atualizarProjetoSelecionado', value)
        },
        atualizarFormasContatoAction({ commit }, value) {
            commit('atualizarFormasContato', value)
        },
        setWhiteAndDarkModeAction({ commit }, value) {
            commit('setWhiteAndDarkMode', value)


        }

    },
    getters: {
        /*Monta parametros para requisições GET via API*/
        empresaFiltrosQuery: state => {
            let estados = state.empresaFiltros.estados.reduce((accumulator, item) => {
                accumulator.push(item.uf)
                return accumulator
            }, [])
            let municipio_ids = state.empresaFiltros.municipios.reduce((accumulator, item) => {
                accumulator.push(item.id)
                return accumulator
            }, [])
            let cnaes = state.empresaFiltros.cnaes.reduce((accumulator, item) => {
                accumulator.push(item.secao + (item.cnae ? item.cnae : ''))
                return accumulator
            }, [])
            let naturezaJuridicas = state.empresaFiltros.naturezaJuridicas.reduce((accumulator, item) => {
                accumulator.push(item.codigo)
                return accumulator
            }, [])
            let departamentos = state.empresaFiltros.departamentos.reduce((accumulator, item) => {
                accumulator.push(item.id)
                return accumulator
            }, [])
            let nivelHierarquicos = state.empresaFiltros.nivelHierarquicos.reduce((accumulator, item) => {
                accumulator.push(item.id)
                return accumulator
            }, [])
            let empresaSituacaos = state.empresaFiltros.empresaSituacaos.reduce((accumulator, item) => {
                accumulator.push(item.value)
                return accumulator
            }, [])
            return {
                q: state.empresaFiltros.q ? state.empresaFiltros.q : null,
                ufs: estados,
                municipio_ids: municipio_ids,
                bairro: state.empresaFiltros.bairro ? state.empresaFiltros.bairro : null,
                logradouro: state.empresaFiltros.logradouro ? state.empresaFiltros.logradouro : null,
                cep: state.empresaFiltros.cep,
                cnaes: cnaes,
                dataAberturaInicio: state.empresaFiltros.dataAberturaInicio,
                dataAberturaFim: state.empresaFiltros.dataAberturaFim,
                capitalSocialMin: state.empresaFiltros.capitalSocialMin,
                capitalSocialMax: state.empresaFiltros.capitalSocialMax,
                matrizFilial: state.empresaFiltros.matrizFilial,
                contatoComTelefone: state.empresaFiltros.contatoComTelefone,
                filiaisMin: state.empresaFiltros.filiaisMin,
                filiaisMax: state.empresaFiltros.filiaisMax,
                opcaoSimples: state.empresaFiltros.opcaoSimples,
                portes: state.empresaFiltros.portes,
                naturezaJuridicas: naturezaJuridicas,
                departamentos: departamentos,
                nivelHierarquicos: nivelHierarquicos,
                faturamentoMin: state.empresaFiltros.faturamentoMin,
                faturamentoMax: state.empresaFiltros.faturamentoMax,
                funcionariosMin: state.empresaFiltros.funcionariosMin,
                funcionariosMax: state.empresaFiltros.funcionariosMax,
                cnpjs: state.empresaFiltros.cnpjs ? state.empresaFiltros.cnpjs : null,
                empresaSituacaos: empresaSituacaos
            }
        },
        listaEstados: state => {
            return state.empresaFiltros.estados.reduce((accumulator, item) => {
                accumulator.push(item.uf)
                return accumulator
            }, [])
        },
        listaEstabEstados: state => {
            return state.medicoFiltros.estabEstados.reduce((accumulator, item) => {
                accumulator.push(item.uf)
                return accumulator
            }, [])
        },
        medicoFiltrosQuery: state => {
            let estados = state.medicoFiltros.estados.reduce((accumulator, item) => {
                accumulator.push(item.uf)
                return accumulator
            }, [])
            let especialidades = state.medicoFiltros.especialidades.reduce((accumulator, item) => {
                accumulator.push(item.id)
                return accumulator
            }, [])
            let sites = state.medicoFiltros.sites.reduce((accumulator, item) => {
                accumulator.push(item.id)
                return accumulator
            }, [])
            let areaAtuacaos = state.medicoFiltros.areaAtuacaos.reduce((accumulator, item) => {
                accumulator.push(item.id)
                return accumulator
            }, [])
            let medicoSituacaos = state.medicoFiltros.medicoSituacaos.reduce((accumulator, item) => {
                accumulator.push(item.cod_situacao)
                return accumulator
            }, [])
            let estabTipos = state.medicoFiltros.estabTipos.reduce((accumulator, item) => {
                accumulator.push(item.id)
                return accumulator
            }, [])
            let estabAtividades = state.medicoFiltros.estabAtividades.reduce((accumulator, item) => {
                accumulator.push(item.id)
                return accumulator
            }, [])
            let estabEstados = state.medicoFiltros.estabEstados.reduce((accumulator, item) => {
                accumulator.push(item.uf)
                return accumulator
            }, [])
            let estabMunicipios = state.medicoFiltros.estabMunicipios.reduce((accumulator, item) => {
                accumulator.push(item.id)
                return accumulator
            }, [])
            let cbos = state.medicoFiltros.cbos.reduce((accumulator, item) => {
                accumulator.push(item.id)
                return accumulator
            }, [])
            let vinculos = state.medicoFiltros.vinculos.reduce((accumulator, item) => {
                accumulator.push(item.id)
                return accumulator
            }, [])
            let formasContato = state.medicoFiltros.formasContato;
            // .reduce((accumulator, item) => {
            //     accumulator.push(item.id)
            //     return accumulator
            // }, [])

            let dataRegistroMin = null
            let dataRegistroMax = null
            if (state.medicoFiltros.dataRegistro) {
                dataRegistroMin = state.medicoFiltros.dataRegistro[0]
                if (state.medicoFiltros.dataRegistro[1]) {
                    dataRegistroMax = state.medicoFiltros.dataRegistro[1]
                }
            }

            return {
                ufs: estados,
                especialidades: especialidades,
                areaAtuacaos: areaAtuacaos,
                sites: sites,
                dataRegistroMin: dataRegistroMin,
                dataRegistroMax: dataRegistroMax,
                estabAtividades: estabAtividades,
                estabTipos: estabTipos,
                estabUfs: estabEstados,
                estabMunicipios: estabMunicipios,
                cbos: cbos,
                vinculos: vinculos,
                formasContato: formasContato,
                crms: state.medicoFiltros.crms,
                medicoSituacaos: medicoSituacaos
            }
        }
    },
    modules: {
        auth,
        leads

    }
})
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import layout from "../layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: layout,
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/Home"),
        meta: { requiresAuth: true },
        redirect: () => {
          if (store.state.auth.status.loggedIn) {
            if (store.state.auth.user.roles) {
              var roles = store.state.auth.user.roles;
              if (roles.includes("admin")) {
                return { name: "Projetos" };
              } else if (roles.includes("operador")) {
                return { name: "Projetos" };
              } else if (roles.includes("cliente-projeto")) {
                return { name: "Projetos" };
              } else if (roles.includes("cliente-b2b")) {
                return { name: "Empresas" };
              } else if (roles.includes("cliente-doktor")) {
                return { name: "MedicoSearch" };
              }
            }
            return { name: "Perfil" };
          } else {
            return { name: "Login" };
          }
        }
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/Login"),
    meta: { guest: true }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/auth/Register"),
    meta: { guest: true }
  },
  {
    path: "/password/reset-request",
    name: "PasswordResetRequest",
    component: () => import("@/views/auth/PasswordResetRequest"),
    meta: { guest: true }
  },
  {
    path: "/password/reset",
    name: "PasswordReset",
    component: () => import("@/views/auth/PasswordReset"),
    meta: { guest: true }
  },
  {
    path: "/email/verificar",
    name: "EmailVerificar",
    component: () => import("@/views/auth/EmailVerificar"),
    meta: { requiresAuth: true }
  },
  {
    path: "/perfil",
    component: layout,
    children: [
      {
        path: "",
        name: "Perfil",
        component: () => import("@/views/auth/Perfil.vue"),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: "/users",
    component: layout,
    children: [
      {
        path: "",
        name: "Users",
        component: () => import("@/views/auth/UserList.vue"),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: "/dashboard",
    component: layout,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("@/views/dashboard"),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: "/projetos",
    component: layout,
    children: [
      {
        path: "",
        name: "Projetos",
        component: () => import("@/views/projetos/Projetos"),
        meta: { requiresAuth: true }
      },
      {
        path: ":id/dashboard",
        name: "ProjetoDashboard",
        component: () => import("@/views/projeto-dashboard/ProjetoDashboard"),
        meta: { requiresAuth: true }
      },
      {
        path: ":id/questionario",
        name: "ProjetoQuestionario",
        component: () => import("@/views/projetos/Questionario"),
        meta: { requiresAuth: true }
      },

      {
        path: ":projetoId/arquivos",
        name: "ProjetoArquivos",
        props: true,
        component: () => import("@/views/projetos/ProjetoArquivos"),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: "/leads",
    component: layout,
    children: [
      {
        path: "",
        name: "Leads",
        props: true,
        component: () => import("@/views/leads/LeadList"),
        meta: { requiresAuth: true }
      },
      {
        path: ":id",
        name: "LeadView",
        props: true,
        component: () => import("@/views/leads/LeadView"),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: "/empresas",
    component: layout,
    children: [
      {
        path: "",
        name: "Empresas",
        component: () => import("@/views/empresas/EmpresaList.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: ":id",
        name: "EmpresaView",
        props: true,
        component: () => import("../views/empresas/EmpresaView.vue"),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: "/contatos",
    component: layout,
    children: [
      {
        path: "",
        name: "Contatos",
        component: () => import("@/views/contatos/ContatoList.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: ":id",
        name: "ContatoView",
        props: true,
        component: () => import("../views/contatos/ContatoView.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "importar",
        name: "ContatoImport",
        props: { importLeads: false },
        component: () => import("../views/contatos/ContatoImport.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "importar",
        name: "LeadImport",
        props: { importLeads: true },
        component: () => import("../views/contatos/ContatoImport.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "add/contato",
        name: "addContato",
        props: { importLeads: true },
        component: () => import("../views/contatos/AddContato.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "edit/:id",
        name: "EditContato",
        props: true,
        component: () => import("../views/contatos/EditContato.vue"),
        meta: { requiresAuth: true }
      }
    ]
  },

  {
    path: "/conversations",
    component: layout,
    children: [
      {
        path: "",
        name: "Conversations",
        component: () => import("@/views/conversation/ChatContainer.vue"),
        meta: { requiresAuth: true }
      }
      // {
      //     path: ":id",
      //     name: "EmpresaView",
      //     props: true,
      //     component: () =>
      //         import ("../views/empresas/EmpresaView.vue"),
      //     meta: { requiresAuth: true }
      // }
    ]
  },

  {
    path: "/clientes",
    component: layout,
    children: [
      {
        path: "",
        name: "Clientes",
        component: () => import("@/views/clientes/ClienteList.vue"),
        meta: { requiresAuth: true }
      }
    ]
  },

  {
    path: "/painel-chamadas",
    component: layout,
    children: [
      {
        path: "",
        name: "PainelChamadas",
        component: () =>
          import("@/views/admin/painel-controle-chamadas/PainelControleUsersList.vue"),
        meta: { requiresAuth: true }
      },

      {
        path: ":id/efetuadas",
        name: "PainelChamadasEfetuadas",
        props: true,
        component: () =>
          import("@/views/admin/painel-controle-chamadas/PainelControleUsersView.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: ":id/recebidas",
        name: "PainelChamadasRecebidasAdmin",
        props: true,
        component: () =>
          import("@/views/admin/painel-controle-chamadas/PainelChamadasRecebidasView.vue"),
        meta: { requiresAuth: true }
      }
    ]
  },

  {
    path: "/chamadas-operador/:id",
    // path: '/:id/chamadas-operador',
    component: layout,
    children: [
      {
        path: "",
        props: true,

        name: "MinhasChamadas",
        component: () =>
          import("@/views/painel-chamadas-operador/ChamadasOperador.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/chamadas-operador/:id/recebidas",
        // path: "/recebidas",
        name: "PainelChamadasRecebidasOperador",
        props: true,
        component: () =>
          import("@/views/painel-chamadas-operador/PainelChamadasRecebidasOperadorView.vue"),
        meta: { requiresAuth: true }
      }
    ]
  },

  {
    path: "/doktor/medicos",
    component: layout,
    children: [
      {
        path: "pesquisa",
        name: "MedicoSearch",
        component: () => import("@/views/doktor/medicos/MedicoSearch.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "",
        name: "MedicoList",
        component: () => import("@/views/doktor/medicos/MedicoList.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "importar",
        name: "MedicoImport",
        props: { importLeads: false },
        component: () => import("../views/doktor/medicos/MedicoImport.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: ":id",
        name: "MedicoView",
        props: true,
        component: () => import("../views/doktor/medicos/MedicoView.vue"),
        meta: { requiresAuth: true }
      },
    ]
  },
  {
    path: "*",
    redirect: "/error-404",
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "error-404",
        component: () => import("@/views/error-pages/error-404")
      }
    ]
  },
  {
    path: "/not-authorized",
    name: "not-authorized",
    component: () => import("@/views/error-pages/NotAuthorized.vue"),
    meta: {
      authRequired: "false"
    }
  }
];

const router = new VueRouter({
  linkExactActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  // Guest Routes
  if (to.matched.some(record => record.meta.guest)) {
    if (store.state.auth.status.loggedIn) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
  // Auth Required Routes
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.auth.status.loggedIn) {
      if (!hasAccess(to.name)) {
        router.push({
          name: "not-authorized"
        });
      }

      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

function hasAccess(name) {
  var permissions = store.state.auth.user.permissions || [];

  switch (name) {
    // Paginas protegidas

    case "Projetos":
      return permissions.includes("Ver Projetos");
    case "Usuarios":
      return permissions.includes("Ver Usuarios");
    case "Clientes":
      return permissions.includes("Ver Clientes");
    case "Empresas":
      return permissions.includes("Ver Empresas");
    case "Contatos":
      return permissions.includes("Ver Contatos");
    case "ContatoImport":
      return permissions.includes("Importar Base");
    case "MedicoImport":
      return permissions.includes("Importar Base");
    case "MedicoSearch":
      return permissions.includes("Ver Medicos");

    default:
      return true;
  }
}

export default router;
